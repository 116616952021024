.results {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
}

.loader {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.noResults {
    align-self: center;
    font-size: 24px;
}