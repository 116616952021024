.footerContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 120px;
  width: 100vw;
  padding: 20px 40px;
  margin-top: 40px;

  box-shadow: rgb(0 0 0 / 16%) 0px 1px 8px;
  background-color: var(--black);
}

.logo {
  width: 200px;
}

.text {
  white-space: normal;
}

.github {
  width: 1px;
  margin-top: 1px;
}

.footerCopyrights {
  background-color: var(--orange);
  width: 100%;
  text-align: center;
  padding: 10px;
  color: var(--black);
  font-weight: bold;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 180px;
  }

  .footerContainer {
    height: 80px;
    padding: 5px 10px;
    margin-top: 20px;
  }

  .footerCopyrights {
    padding: 5px;
  }
}