.mainDiv {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin: 25px 0;
}

.movieDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;

  padding-bottom: 60px;
  background-color: var(--darkBlack);
  color: var(--white);
}

.videoDiv {
  width: 100%;
  height: 80vh;
  color: white;
}

.detailsCard {
  display: flex;
  column-gap: 40px;

  width: 100%;
  padding: 40px;

  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 8px;
  background-color: var(--black);
  color: var(--white);
}

.movieImage {
  border-radius: 12px;
  width: 200px;
  height: 400px;
  object-fit: cover;
}

.otherDetails {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.movieTitle {
  font-size: 36px;
  font-weight: 500;
}

.infoAndSeasons {
  display: flex;
  column-gap: 100px;
  justify-content: center;
  align-items: flex-start;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.seasonsList {
  overflow: auto;
}

.episodesList {
  display: flex;
  column-gap: 3px;
  flex-wrap: wrap;
}

.episodeTitle {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.episode {
  display: inline-block;
  background-color: var(--orange);
  color: var(--black);
  border-radius: 20px;
  padding: 6px 8px;
  cursor: pointer;
}

.activeEpisode {
  border: 3px solid var(--white);
}

.ratingAndDuration {
  display: flex;
  column-gap: 30px;
  font-size: 20px;
  align-items: center;
  color: var(--orange);
}

.rating {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.production {
  font-size: 22px;
}

.releaseDate {
  font-size: 20px;
}

.genres {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.tag {
  display: inline-block;
  background-color: var(--orange);
  color: var(--black);
  border-radius: 20px;
  padding: 6px 8px;
  margin-bottom: 5px;
  font-size: 18px;
}

.cast {
  font-size: 20px;
}

.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

@media only screen and (max-width: 768px) {
  .mainDiv {
    margin: 20px 0;
  }

  .videoDiv {
    height: 34vh;
  }

  .detailsCard {
    width: 90%;
    flex-direction: column;
    row-gap: 16px;
    padding: 30px;
  }

  .movieImage {
    border-radius: 12px;
    width: 200px;
    height: 200px;
  }

  .movieTitle {
    font-size: 30px;
  }

  .production,
  .releaseDate {
    font-size: 20px;
  }

  .ratingAndDuration,
  .cast,
  .tag {
    font-size: 18px;
  }

  .otherDetails {
    row-gap: 14px;
  }

  .rightDiv {
    width: 80vw;
  }

  .infoAndSeasons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    padding: 0 20px;
  }
}