.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 0;
}

.logo {
    width: 240px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .logo {
        width: 200px;
        margin-bottom: 15px;
    }
}